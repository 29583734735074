<template>
    <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
        cancelText="取消" centered okText="确认" width="400px" @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" layout="vertical" >
            <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
                <a-input v-if="item.type == 'input'" v-decorator="[item.name, { rules: item.rules }]" placeholder="请输入" />
                <a-upload
                    v-if="item.type == 'upload'"
                    accept='.png,.jpeg,.jpg'
                    list-type="picture-card"
                    :show-upload-list="false"
                    :data="uploadParams"
                    :action="uploadUrl"
                    :beforeUpload="beforeUpload"
                    v-decorator="[item.name, { rules: item.rules }]"
                    @change="changeImg">
                    <img style="max-width:130px;max-height:130px" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                    </div>
                </a-upload>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
const formData = [
    {
        name: 'title',
        label: '图片标题',
        type: 'input',
        rules: [{ required: true, message: '请输入图片标题!' }]
    },
    {
        name: 'imgUrl',
        label: '图片',
        type: 'upload',
        rules: [{ required: true, message: '请上传图片!' }]
    },
]
    import url from '@/utils/URL'
    import createFilePath from '@/utils/tools'
    export default {
        name:'imageModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                ModalTitle: '添加图片',
                uploadLoading: false,
                visible: false,
                confirmLoading: false,
                imageUrl:'',
                fileList:[],
                uploadParams: {},
                uploadUrl: url.uploadQiNiu,
                form: this.$form.createForm(this),
            }
        },
        props:{
            item:Object
        },
        async created () {
            this.visible = true
            await this.$nextTick()
            if(this.item){
                this.imageUrl = this.item.image.pic_url
                this.form.setFieldsValue({title:this.item.image.title,imgUrl:this.item.image.pic_url})
            }
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                this.confirmLoading = true
                await this.$store.dispatch('missionUploadmediaAction',{data:{file_type:'image',file_url:this.imageUrl}})
                .then(res=>{
                    this.confirmLoading = false
                    console.log(params)
                    let obj = {
                        msgtype: "image",
                        image: {
                            title: params.title,
                            media_id: res.data.media_id,
                            pic_url: this.imageUrl
                        }
                    }
                    if(this.item){
                        this.parent.hideImageModal(1,obj)
                    }else{
                        this.parent.hideImageModal(0,obj)
                    }
                })
                .catch(err=>{
                    this.confirmLoading = false
                })
                
            },
            handleCancel() {
                this.parent.hideImageModal()
            },
            async beforeUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeImg({ file, fileList }){
                this.uploadLoading = true
                if(file.status == "done"){
                    this.fileList.push(file)
                    this.imageUrl = `http://i01.wedots.cn/${file.response.key}`
                    this.uploadLoading = false
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>