<template>
    <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
        cancelText="取消" centered okText="确认" width="450px" @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" layout="vertical" >
            <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
                <a-input v-if="item.type == 'input'" v-decorator="[item.name, { rules: item.rules }]" placeholder="请输入" />
                <a-upload
                    v-if="item.type == 'upload'"
                    accept='.png,.jpeg,.jpg'
                    list-type="picture-card"
                    :show-upload-list="false"
                    :data="uploadParams"
                    :action="uploadUrl"
                    :beforeUpload="beforeUpload"
                    v-decorator="[item.name, { rules: item.rules }]"
                    @change="changeImg">
                    <img style="max-width:130px;max-height:130px" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                    </div>
                </a-upload>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
const formData = [
    {
        name: 'linkUrl',
        label: '链接地址',
        type: 'input',
        rules: [{ required: true, message: '请输入链接地址!' }]
    },
    {
        name: 'title',
        label: '链接标题',
        type: 'input',
        rules: [{ required: true, message: '请输入链接标题!' }]
    },
    {
        name: 'desc',
        label: '链接描述',
        type: 'input',
        rules: [{ required: true, message: '请输入链接描述!' }]
    },
    {
        name: 'picurl',
        label: '链接封面',
        type: 'upload',
        rules: [{ required: true, message: '请上传链接封面!' }]
    },
]
    import url from '@/utils/URL'
    import createFilePath from '@/utils/tools'
    export default {
        name:'linkModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                ModalTitle: '添加链接附件',
                uploadLoading: false,
                visible: false,
                confirmLoading: false,
                imageUrl:'',
                fileList:[],
                uploadParams: {},
                uploadUrl: url.uploadQiNiu,
                form: this.$form.createForm(this),
            }
        },
        props:{
            item:Object
        },
        async created () {
            this.visible = true
            await this.$nextTick()
            if(this.item){
                this.imageUrl = this.item.link.picurl
                this.form.setFieldsValue({
                    desc: this.item.link.desc,
                    picurl: this.item.link.picurl,
                    title: this.item.link.title,
                    linkUrl: this.item.link.url
                })
            }
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                let obj = {
                    msgtype: 'link',
                    link: {
                        desc: params.desc,
                        picurl: this.imageUrl,
                        title: params.title,
                        type: 'normal_link',
                        url: params.linkUrl
                    }
                }
                if(this.item){
                    this.parent.hideLinkModal(1,obj)
                }else{
                    this.parent.hideLinkModal(0,obj)
                }
            },
            handleCancel() {
                this.parent.hideLinkModal()
            },
            async beforeUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeImg({ file, fileList }){
                this.uploadLoading = true
                if(file.status == "done"){
                    this.fileList.push(file)
                    this.imageUrl = `http://i01.wedots.cn/${file.response.key}`
                    this.uploadLoading = false
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .linkModal{
        &-checkbox{
            color: #bfbfbf;
            font-size: 12px;
        }
    }
</style>