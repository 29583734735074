<template>
    <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
        cancelText="取消" centered okText="确认" width="400px" @ok="handleOk" @cancel="handleCancel">
        <a-form layout="vertical">
            <a-form-item  label="选择表单">
                <a-select placeholder="请选择" :not-found-content="fetching ? undefined : null"
                    v-model="survey_id" :filterOption="filterOption" @search="handleSearch" showSearch allowClear label-in-value>
                    <template slot="notFoundContent">
                        <div style="height:50px">
                            <vue-element-loading :active="fetching" color="#00cca2" spinner="spinner"/>
                        </div>
                    </template>
                    <a-select-option v-for="(d, index) of survey" :key="index" :value="d.survey_id">{{ d.filter_name }}</a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    export default {
        name:'appletsModal',
        inject: ['parent'],
        data() {
            return {
                ModalTitle: '自定义表单',
                visible: false,
                confirmLoading: false,
                fetching: false,
                survey:[],
                survey_id:'',
            }
        },
        props:{
            item:Object
        },
        async created () {
            this.visible = true
            this.getSurvey()
            await this.$nextTick()
            if(this.item){
                this.survey_id = {
                    label:this.item.link.title,
                    key:this.item.link.survey_id,
                }
            }
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getSurvey(obj) {
                this.fetching = true
                let res = await this.$store.dispatch('searchSurveyAction', {params:obj})
                this.fetching = false
                this.survey = res.data
            },
            handleOk() {
                if(!this.survey_id){
                    this.$message.warning('请选择表单')
                    return false
                }
                let itemObj = {}
                this.survey.forEach(item=>{
                    if(item.survey_id == this.survey_id.key){
                        itemObj = item
                    }
                })
                let obj = {
                    msgtype: 'link',
                    link: {
                        desc: itemObj.survey_descr,
                        picurl: itemObj.survey_image,
                        title: itemObj.filter_name,
                        survey_id: itemObj.survey_id,
                        type: 'form_link',
                        url: itemObj.access_url
                    }
                }
                if(this.item){
                    this.parent.hideFormModal(1,obj)
                }else{
                    this.parent.hideFormModal(0,obj)
                }
            },
            handleCancel() {
                this.parent.hideFormModal()
            },
            handleSearch(val){
                let obj = {q:val}
                this.getSurvey(obj)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>