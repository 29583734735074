<template>
    <div class="zl-list">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场应用</a-breadcrumb-item>
            <a-breadcrumb-item>渠道活码</a-breadcrumb-item>
            <a-breadcrumb-item>新建活码</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="title flts-title">
                <h3>基础信息</h3>
                <div>
                    <a-button size="small" style="margin-left:10px" @click="handleCancel">返回</a-button>
                </div>
            </div>
            <div>
                <a-form>
                    <a-form-item label="二维码名称" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-input style="width:500px" placeholder="请输入二维码名称" />
                    </a-form-item>
                    <a-form-item label="客服人员" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-radio-group v-model="user">
                            <a-radio :value="1">全天在线</a-radio>
                            <a-radio :value="2">自动上下线</a-radio>
                        </a-radio-group>
                        <div v-if="user == 1">
                            <a-popover v-model="isCheckWarp" title="选择" trigger="click">
                                <div slot="content">
                                <checkWarp />
                                </div>
                                <div>
                                    <a-button icon="plus" >添加成员</a-button>
                                    <span style="margin-left:10px">同一个二维码可选择多位客服成员进行接待，客户扫码后随机分配一名客服人员</span>
                                </div>
                            </a-popover>
                            <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
                                <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
                            </div>
                        </div>
                        <div v-if="user == 2">
                            <div class="tool-checkWarp-down-up">
                                <div>自动上下线：</div>
                                <div>
                                    <div>1、可用于员工早晚班等不同上班时间段使用；</div>
                                    <div>2、员工在非线上时间将不再接待新客户。</div>
                                </div>
                            </div>
                            <div v-for="(item,index) in workCycle" :key="index" class="tool-checkWarp-work-cycle">
                                <a-form>
                                    <a-form-item label="员工选择" :label-col="{span:3}" :wrapper-col="{span:21}">
                                        <a-popover v-model="isCheckWarp" title="选择" trigger="click">
                                            <div slot="content">
                                            <checkWarp />
                                            </div>
                                            <div>
                                                <a-button icon="plus" >添加成员</a-button>
                                            </div>
                                        </a-popover>
                                        <div v-if="check_name_list.length>0" style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
                                            <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
                                        </div>
                                    </a-form-item>
                                    <a-form-item label="上线时间" :label-col="{span:3}" :wrapper-col="{span:21}">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='onlineTime'
                                        allowClear/>
                                    </a-form-item>
                                    <a-form-item label="工作周期" :label-col="{span:3}" :wrapper-col="{span:21}">
                                        <a-select mode="multiple" placeholder="请选择">
                                            <a-select-option v-for="(d, index) of classWeeklys" :key="index" :value="d.value">{{ d.label}}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-form>
                                <div v-if="index != 0" class="tool-checkWarp-work-cycle-del">
                                    <a-icon :style="{fontSize:'24px'}" type="delete" />
                                </div>
                            </div>
                            <div @click="workCycle.push(1)" style="color:#00cca2">
                                <a-icon type="plus-circle" />
                                <span style="margin-left:5px;cursor:pointer">添加其他工作周期</span>
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item label="员工添加上限" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-switch checked-children="已开启" un-checked-children="已关闭" :checked="addLimit" @change="addLimit = !addLimit" />
                        <div v-if="addLimit">
                            <div class="tool-checkWarp-down-up">
                                员工添加上限：员工从该渠道添加的客户达到每日上限后，将自动下线当日不再接待该渠道新客户
                            </div>
                            <div style="width:650px;margin-top:10px">
                                <div class="clearfix table-tools">
                                    <div class="buttons">
                                        <a-form layout='inline'>
                                            <a-form-item>
                                                <a-popover v-model="limitVisible" title="" trigger="click">
                                                    <div slot="content">
                                                        <div>
                                                            <span>员工每日添加上限：</span>
                                                            <a-input-number v-model="addLimitNum" :min="0"/>
                                                        </div>
                                                        <div style="text-align:right;margin-top:10px">
                                                            <a-button style="margin-right:5px">取消</a-button>
                                                            <a-button type="primary">确认</a-button>
                                                        </div>
                                                    </div>
                                                    <div>批量设置</div>
                                                </a-popover>
                                            </a-form-item>
                                        </a-form>
                                    </div>
                                    <div class="search">
                                        <a-form layout='inline'>
                                        <a-form-item>
                                            <a-tooltip placement="topLeft" >
                                            <template slot="title">
                                                <span>姓名</span>
                                            </template>
                                            <a-input v-model="nickname" placeholder="请输入姓名" style="width: 160px"/>
                                            </a-tooltip>
                                        </a-form-item>
                                        </a-form>
                                    </div>
                                </div>
                                <div class="table">
                                    <a-table :pagination="false" :bordered='false' rowKey="employee_id"
                                    :columns="columns" :dataSource="employee_list">
                                        
                                    </a-table>
                                </div>
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item label="允许员工上下线" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-switch checked-children="已开启" un-checked-children="已关闭" :checked="allowUser" @change="allowUser = !allowUser" />
                        <span style="margin-left:10px">开启后员工可在侧边栏修改个人上下线状态</span>
                    </a-form-item>
                    <a-form-item label="备用员工" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-popover v-model="isCheckWarp" title="选择" trigger="click">
                            <div slot="content">
                                <checkWarp />
                            </div>
                            <div>
                                <a-button icon="plus" >必填，请选择备用员工</a-button>
                                <span style="margin-left:10px">当活码中所有员工均不可接待时，将分配客户给该员工</span>
                            </div>
                        </a-popover>
                        <div v-if="check_name_list.length>0" style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
                            <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
                        </div>
                    </a-form-item>
                    <a-form-item label="客户备注" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <div>
                            <a-switch checked-children="已开启" un-checked-children="已关闭" :checked="customerRemark" @change="customerRemark = !customerRemark" />
                            <span style="margin-left:10px">开启后可为客户昵称加备注，方便查看客户来源</span>
                        </div>
                        <div v-if="customerRemark">
                            <a-input-group compact>
                                <a-input style="width: 8%" default-value="客户昵称-" />
                                <a-input style="width: 30%" v-model="customername" placeholder="请输入客户备注（备注将展示在客户昵称后）" />
                            </a-input-group>
                            <div style="display:flex">
                                <div>预览：</div>
                                <div>
                                    <a-avatar shape="square" :size="34" icon="user" />
                                    <span style="margin-left:10px">客户昵称-{{customername}}</span>
                                </div>
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item label="客户描述" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-switch checked-children="已开启" un-checked-children="已关闭" :checked="customerdesc" @change="customerdesc = !customerdesc" />
                        <span style="margin-left:10px">开启后可为客户添加描述，将在客户画像里展示</span>
                        <div v-if="customerdesc">
                            <a-textarea style="width:650px" placeholder="请输入客户描述内容" :rows="4" />
                        </div>
                    </a-form-item>
                </a-form>
            </div>
            <div class="title flts-title">
                <h3>设置欢迎语</h3>
            </div>
            <div>
                <a-alert message="因企业微信限制，若使用成员已在「企业微信后台」配置了欢迎语，这里的欢迎语将不会生效" banner />
                <a-form>
                    <a-form-item label="设置欢迎语" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-radio-group v-model="welcome">
                            <a-radio :value="1">渠道欢迎语</a-radio>
                            <a-radio :value="2">默认欢迎语</a-radio>
                            <a-radio :value="3">不发欢迎语</a-radio>
                        </a-radio-group>
                        <div v-if="welcome == 1">
                            <div class="tool-welcome">
                                <div style="flex:1">
                                    <a-form>
                                        <a-form-item label="消息内容" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <div class="tool-welcome-textarea">
                                                <a-textarea placeholder="请输入回复内容" :rows="20" />
                                            </div>
                                            <div class="tool-welcome-enclosure">
                                                <div>
                                                    <div v-for="(item,index) in attachments" :key="index" class="tool-welcome-enclosure-list">
                                                        <a-icon @click="delEnclosure(index)" type="minus-circle" />
                                                        <span v-if="item.msgtype == 'image'">【图片】:</span>
                                                        <span v-if="item.link && item.link.type == 'normal_link'">【链接】:</span>
                                                        <span v-if="item.link && item.link.type == 'form_link'">【自定义表单】:</span>
                                                        <span v-if="item.msgtype == 'image'">{{item.image.title}}</span>
                                                        <span v-if="item.msgtype == 'link'">{{item.link.title}}</span>
                                                        <a-icon @click="toEdit(item,index)" class="tool-welcome-enclosure-edit" type="form" />
                                                    </div>
                                                </div>
                                                <a-dropdown :trigger="['click']">
                                                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                                        <a-icon type="plus-circle" />
                                                        <span style="margin-left:10px">添加附件</span>
                                                    </a>
                                                    <a-menu slot="overlay">
                                                        <a-menu-item @click="showImageModal()" key="1">
                                                            图片
                                                        </a-menu-item>
                                                        <a-menu-item @click="showLinkModal()" key="2">
                                                            链接
                                                        </a-menu-item>
                                                        <a-menu-item @click="showFormModal()" key="3">
                                                            自定义表单
                                                        </a-menu-item>
                                                    </a-menu>
                                                </a-dropdown>
                                            </div>
                                        </a-form-item>
                                    </a-form>
                                </div>
                                <div style="width:274px;margin-top:-30px">
                                    <img style="width:274px" src="@/assets/phone.png">
                                </div>
                            </div>
                        </div>
                        <div v-if="welcome == 2">
                            将发送成员已设置的欢迎语，若所选成员未设置欢迎语，则不会发送欢迎语
                        </div>
                    </a-form-item>
                    <a-form-item label="分时段欢迎语" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-switch checked-children="已开启" un-checked-children="已关闭" :checked="welcomeTime" @change="welcomeTime = !welcomeTime" />
                        <div v-show="welcomeTime" class="tool-checkWarp-down-up" style="margin-bottom:15px">
                            <div>分时段欢迎语：</div>
                            <div>
                                <div>1、员工上下班不同时间段可设置不同欢迎语；</div>
                                <div>2、分时段之外的时间将发送渠道欢迎语。</div>
                            </div>
                        </div>
                        <div v-show="welcomeTime" v-for="(item,index) in welcomeTimeList" :key="index" class="tool-welcome-time">
                            <div class="tool-welcome-time-bg">
                                <a-form layout="vertical">
                                    <a-form-item label="发送日期">
                                        <div style="display:flex">
                                            <a-select style="margin-right:10px" mode="multiple" placeholder="请选择">
                                                <a-select-option v-for="(d, index) of classWeeklys" :key="index" :value="d.value">{{ d.label}}</a-select-option>
                                            </a-select>
                                            <a-range-picker
                                            style="240px"
                                            :ranges="rangesData"
                                            v-model='onlineTime'
                                            allowClear/>
                                        </div>
                                    </a-form-item>
                                    <a-form-item label="">
                                        <div class="tool-welcome-textarea">
                                            <a-textarea placeholder="请输入回复内容" :rows="20" />
                                        </div>
                                        <div class="tool-welcome-enclosure">
                                            <div>
                                                <div v-for="(item,index) in attachments" :key="index" class="tool-welcome-enclosure-list">
                                                    <a-icon @click="delEnclosure(index)" type="minus-circle" />
                                                    <span v-if="item.msgtype == 'image'">【图片】:</span>
                                                    <span v-if="item.link && item.link.type == 'normal_link'">【链接】:</span>
                                                    <span v-if="item.link && item.link.type == 'form_link'">【自定义表单】:</span>
                                                    <span v-if="item.msgtype == 'image'">{{item.image.title}}</span>
                                                    <span v-if="item.msgtype == 'link'">{{item.link.title}}</span>
                                                    <a-icon @click="toEdit(item,index)" class="tool-welcome-enclosure-edit" type="form" />
                                                </div>
                                            </div>
                                            <a-dropdown :trigger="['click']">
                                                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                                    <a-icon type="plus-circle" />
                                                    <span style="margin-left:10px">添加附件</span>
                                                </a>
                                                <a-menu slot="overlay">
                                                    <a-menu-item @click="showImageModal()" key="1">
                                                        图片
                                                    </a-menu-item>
                                                    <a-menu-item @click="showLinkModal()" key="2">
                                                        链接
                                                    </a-menu-item>
                                                    <a-menu-item @click="showFormModal()" key="3">
                                                        自定义表单
                                                    </a-menu-item>
                                                </a-menu>
                                            </a-dropdown>
                                        </div>
                                    </a-form-item>
                                </a-form>
                            </div>
                            <div style="width:274px;margin-left:25px">
                                <img style="width:274px" src="@/assets/phone.png">
                            </div>
                            <div class="tool-welcome-time-label">时段1：</div>
                            <div class="tool-welcome-time-del">
                                <a-icon :style="{fontSize:'24px'}" type="delete" />
                            </div>
                        </div>
                        <div v-show="welcomeTime" style="color:#00cca2">
                            <a-icon type="plus-circle" />
                            <span style="margin-left:5px;cursor:pointer">添加分时段欢迎语</span>
                        </div>
                    </a-form-item>
                    <a-form-item label="欢迎语屏蔽" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-switch checked-children="已开启" un-checked-children="已关闭" :checked="welcomeShield" @change="welcomeShield = !welcomeShield" />
                        <span style="margin-left:10px">开启后，客户昵称中包含设定的关键词的客户不会收到欢迎语</span>
                        <div v-if="welcomeShield">
                            <a-tag v-for="(item,index) in tagList" :key="index">{{item}}</a-tag>
                                <a-input
                                v-if="inputVisible"
                                ref="input"
                                type="text"
                                size="small"
                                :style="{ width: '78px' }"
                                :value="inputValue"
                                @change="handleInputChange"
                                @blur="handleInputConfirm"
                                @keyup.enter="handleInputConfirm"
                                />
                                <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
                                <a-icon type="plus" /> 添加屏蔽昵称
                            </a-tag>
                        </div>
                    </a-form-item>
                </a-form>
            </div>
            <div class="title flts-title">
                <h3>功能设置</h3>
            </div>
            <div>
                <a-form>
                    <a-form-item label="自动通过好友" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-switch checked-children="已开启" un-checked-children="已关闭" :checked="automaticPass" @change="automaticPass = !automaticPass" />
                        <a-tooltip>
                            <template slot="title">
                            开启后，客户添加该企业微信时，无需好友验证，将会自动添加成功
                            </template>
                            <a-icon style="margin-left:5px" type="question-circle" />
                        </a-tooltip>
                        <a-form>
                            <a-form-item v-if="automaticPass" label="开启时间" :label-col="labelCol" :wrapper-col="wrapperCol">
                                <a-radio-group :default-value="1">
                                    <a-radio :value="1">全天开启</a-radio>
                                    <a-radio :value="2">选择时间段</a-radio>
                                </a-radio-group>
                            </a-form-item>
                            <a-form-item label="二维码预览" :label-col="labelCol" :wrapper-col="wrapperCol">
                                <div class="tool-qrcord">
                                    <div class="tool-qrcord-img">
                                        <img style="width:200px" src="@/assets/qr_qr-preview.png">
                                        <a-avatar class="tool-qrcord-avatar" shape="square" :size="40" :src="imageUrl" icon="user" />
                                    </div>
                                    <div class="tool-qrcord-upload">
                                        <a-upload
                                            accept='.png,.jpeg,.jpg'
                                            list-type="picture-card"
                                            :show-upload-list="false"
                                            :data="uploadParams"
                                            :action="uploadUrl"
                                            :beforeUpload="beforeUpload3"
                                            @change="changeImg3">
                                            <img style="width:102px;height:102px" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                                            <div v-else>
                                                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                                            </div>
                                        </a-upload>
                                    </div>
                                </div>
                            </a-form-item>
                        </a-form>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <imageModal :item="modalData" v-if="isImageModal" />
        <linkModal :item="modalData" v-if="isLinkModal" />
        <formModal :item="modalData" v-if="isFormModal" />
    </div>
</template>

<script>
const columns = [
    { title: '员工名称', dataIndex: 'name', key: 'name'},
    { title: '添加上限', dataIndex: 'limit', key: 'limit'},
]
    import moment from 'moment'
    import url from '@/utils/URL'
    import C_ITEMS from '@/utils/items'
    import checkWarp from './CheckWarp'
    import imageModal from './imageModal'
    import linkModal from './linkModal'
    import formModal from './formModal'
    import createFilePath from '@/utils/tools'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:'editModal',
        data() {
            return {
                columns,
                labelCol: { span: 2 },
                wrapperCol: { span: 20 },
                isCheckWarp:false,
                check_name_list:[],
                user:2,
                classWeeklys:C_ITEMS.classWeeklys,
                workCycle:[1],
                onlineTime:[],
                addLimit:false,
                limitVisible:false,
                addLimitNum:100,
                employee_list:[],
                nickname:'',
                allowUser:false,
                customerRemark:false,
                customername:'',
                customerdesc:false,
                welcome:1,
                welcomeShield:false,
                tagList:[],
                inputVisible:false,
                inputValue:'',
                welcomeTime:true,
                welcomeTimeList:[1],
                fileList1:[],
                fileList2:[],
                fileList3:[],
                imageUrl:'',
                uploadParams: {},
                uploadLoading:false,
                uploadUrl: url.uploadQiNiu,
                automaticPass:false,



                attachments: [],
                isImageModal: false,
                isLinkModal: false,
                isFormModal: false,
                modalData: {}
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            checkWarp,
            imageModal,
            linkModal,
            formModal
        },
        mixins:[ranges],
        created () {
            this.$ls.remove('check_name_list')
            this.$ls.remove('send_employee_ids')
            this.$ls.remove('send_employee_list')
        },
        methods: {
            hideCheckWarp(val){
                if(val){
                    this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                }
                this.isCheckWarp = false
            },
            handleCancel() {
                this.$router.go(-1)
            },
            showInput() {
                this.inputVisible = true;
                this.$nextTick(function() {
                    this.$refs.input.focus()
                })
            },
            handleInputChange(e) {
                this.inputValue = e.target.value
            },
            handleInputConfirm() {
                const inputValue = this.inputValue
                let tagList = this.tagList
                if (inputValue && tagList.indexOf(inputValue) === -1) {
                    tagList = [...tagList, inputValue]
                }
                Object.assign(this, {
                    tagList,
                    inputVisible: false,
                    inputValue: '',
                });
            },
            beforeUpload1(){},
            beforeUpload2(){},
            async beforeUpload3(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeImg1(){},
            changeImg2(){},
            changeImg3({ file, fileList }){
                this.uploadLoading = true
                console.log(file,fileList)
                if(file.status == "done"){
                    this.fileList3.push(file)
                    this.imageUrl = `http://i01.wedots.cn/${file.response.key}`
                    this.uploadLoading = false
                }
            },





            showImageModal(item){
                this.modalData = item
                this.isImageModal = true
            },
            hideImageModal(num,val){
                if(num === 1){
                    this.attachments[this.editIndex] = val
                }else if(num === 0){
                    this.attachments.push(val)
                }
                this.isImageModal = false
            },
            showLinkModal(item){
                this.modalData = item
                this.isLinkModal = true
            },
            hideLinkModal(num,val){
                if(num === 1){
                    this.attachments[this.editIndex] = val
                }else if(num === 0){
                    this.attachments.push(val)
                }
                this.isLinkModal = false
            },
            showFormModal(item){
                this.modalData = item
                this.isFormModal = true
            },
            hideFormModal(num,val){
                if(num === 1){
                    this.attachments[this.editIndex] = val
                }else if(num === 0){
                    this.attachments.push(val)
                }
                this.isFormModal = false
            },
            delEnclosure(index){
                this.attachments.splice(index,1)
            },
            toEdit(item,index){
                this.editIndex = index
                if(item.msgtype == 'image'){
                    this.showImageModal(item)
                }else if(item.link && item.link.type == 'normal_link'){
                    this.showLinkModal(item)
                }else{
                    this.showFormModal(item)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .tool-checkWarp-down-up{
        display: flex;
        border:1px solid #00cca2;
        background: #00cca224;
        border-radius: 2px;
        line-height: 24px;
        padding: 10px;
        width: 650px;
    }
    .tool-checkWarp-work-cycle{
        width: 650px;
        background: #f8f8f8;
        border: 1px solid #e8e8ee;
        margin-top: 10px;
        padding: 10px;
        border-radius: 2px;
        position: relative;
    }
    .tool-checkWarp-work-cycle-del{
        position: absolute;
        bottom: 0;
        right: -32px;
        cursor: pointer;
    }
    .tool-welcome{
        display: flex;
        // &-textarea{
        //     background: #fbfbfb;
        //     border: 1px solid #e8e8e8;
        //     border-radius: 2px;
        //     &-name{
        //         border-bottom: 1px dashed #e8e8e8;
        //         padding-left: 10px;
        //     }
        //     textarea{
        //         resize: none;
        //         border: none;
        //         background: #fbfbfb;
        //     }
        //     textarea:focus{
        //         box-shadow: none;
        //     }
        // }
        // &-radio{
        //     padding-left:10px
        // }
        &-textarea{
            background: #fbfbfb;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            &-name{
                border-bottom: 1px dashed #e8e8e8;
                padding-left: 10px;
            }
            textarea{
                resize: none;
                border: none;
                background: #fbfbfb;
            }
            textarea:focus{
                box-shadow: none;
            }
        }
        &-radio{
            padding-left:10px
        }
        &-enclosure{
            background: #fbfbfb;
            border: 1px solid #e8e8e8;
            border-top: none;
            padding: 0 15px;
            &-list{
                position: relative;
                display: flex;
                align-items: center;
            }
            &-edit{
                position: absolute;
                right: 0;
            }
        }
    }
    .tool-welcome-time{
        display: flex;
        position: relative;
        &-textarea{
            background: #fff;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            &-name{
                border-bottom: 1px dashed #e8e8e8;
                padding-left: 10px;
            }
            textarea{
                resize: none;
                border: none;
                background: #fff;
            }
            textarea:focus{
                box-shadow: none;
            }
        }
        &-radio{
            padding-left:10px
        }
        &-bg{
            padding: 15px 20px;
            background: #fbfbfb;
            border: 1px solid #e8e8ee;
            border-radius: 2px;
            flex: 1;
        }
        &-label{
            position: absolute;
            left: -50px;
            top: 0;
        }
        &-del{
            position: absolute;
            right: 260px;
            bottom: 0;
        }
    }
    .tool-qrcord{
        display: flex;
        &-upload{
            position: relative;
            .ant-upload-picture-card-wrapper{
                position: absolute;
                bottom: 0;
            }
        }
        &-img{
            position: relative;
        }
        &-avatar{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -20px;
            margin-top: -20px;
        }

    }
</style>
<style lang="scss">
.tool-welcome-time{
    .ant-form-item-control{
        line-height: 40px;
    }
}
</style>